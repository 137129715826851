var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"pa-8"},[_c('h3',[_vm._v("ข้อมูลผู้ใช้งาน")]),_c('v-row',{staticClass:"text-end"},[_c('v-col',{attrs:{"cols":"2"}},[_c('p',{staticClass:"pt-2"},[_vm._v("ชื่อจริง")])]),_c('v-col',{attrs:{"cols":"4"}},[_c('v-text-field',{attrs:{"label":"ชื่อจริง","outlined":"","dense":""},model:{value:(_vm.firstName),callback:function ($$v) {_vm.firstName=$$v},expression:"firstName"}})],1),_c('v-spacer'),_c('v-col',{attrs:{"cols":"2"}},[_c('p',{staticClass:"pt-2"},[_vm._v("นามสกุล")])]),_c('v-col',{attrs:{"cols":"4"}},[_c('v-text-field',{attrs:{"label":"นามสกุล","outlined":"","dense":""},model:{value:(_vm.lastName),callback:function ($$v) {_vm.lastName=$$v},expression:"lastName"}})],1)],1),_c('v-row',{staticClass:"text-end"},[_c('v-col',{attrs:{"cols":"2"}},[_c('p',{staticClass:"pt-2"},[_vm._v("อีเมล")])]),_c('v-col',{attrs:{"cols":"4"}},[_c('v-text-field',{attrs:{"label":"อีเมล","outlined":"","dense":""},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}})],1),_c('v-spacer'),_c('v-col',{attrs:{"cols":"2"}},[_c('p',{staticClass:"pt-2"},[_vm._v("หมายเลขโทรศัพท์")])]),_c('v-col',{attrs:{"cols":"4"}},[_c('v-text-field',{attrs:{"label":"หมายเลขโทรศัพท์","outlined":"","dense":""},model:{value:(_vm.tel),callback:function ($$v) {_vm.tel=$$v},expression:"tel"}})],1)],1),_c('v-divider',{staticClass:"mb-6"}),_c('v-row',{staticClass:"text-end"},[_c('v-col',{attrs:{"cols":"2"}},[_c('p',{staticClass:"pt-2"},[_vm._v("ธนาคาร")])]),_c('v-col',{attrs:{"cols":"4"}},[_c('v-autocomplete',{attrs:{"item-text":"name","items":[
          'ธนาคารกรุงเทพฯ',
          'ธนาคารไทยพาณิชย์ฯ',
          'ธนาคารกสิกรไทย',
          'ธนาคารกรุงศรีอยุธยา',
          'ธนาคารธนชาต',
          'ธนาคารกรุงไทย',
          'ธนาคารทิสโก้',
          'ธนาคารออมสิน',
          'ธนาคารอาคารสงเคราะห์',
          'ธนาคารอิสลามแห่งประเทศไทย',
          'ธนาคารเพื่อการเกษตรและสหกรณ์การเกษตร',
          'ธนาคารทหารไทยธนชาต' ],"label":"ธนาคาร","outlined":"","dense":""},model:{value:(_vm.bankName),callback:function ($$v) {_vm.bankName=$$v},expression:"bankName"}})],1),_c('v-spacer'),_c('v-col',{attrs:{"cols":"2"}},[_c('p',{staticClass:"pt-2"},[_vm._v("หมายเลขบัญชีธนาคาร")])]),_c('v-col',{attrs:{"cols":"4"}},[_c('v-text-field',{attrs:{"label":"หมายเลขบัญชีธนาคาร","outlined":"","dense":""},model:{value:(_vm.bankNo),callback:function ($$v) {_vm.bankNo=$$v},expression:"bankNo"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"4"}},[_c('p',{staticClass:"pt-2"},[_vm._v("รูปบัตรประจำตัวประชาชน")])]),_c('v-img',{attrs:{"src":_vm.idCardImgUrl}})],1),_c('v-row',[_c('v-col',{attrs:{"cols":"4"}},[_c('p',{staticClass:"pt-2"},[_vm._v("รูปถ่ายคู่บัตรประชาชน")])]),_c('v-img',{attrs:{"src":_vm.idCardWithPersonImgUrl}})],1),_c('v-row',[_c('v-btn',{on:{"click":function($event){return _vm.cancel()}}},[_vm._v("กลับ")]),_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.submit()}}},[_vm._v("แก้ไข")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }